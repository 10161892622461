// 个人中心 // 播放历史
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>
    <div class="history_body" v-if="$store.state.islogin">
      <!-- 左侧 -->
      <Leftside></Leftside>

      <!-- 右侧主题 -->
      <transition name="RightToleft">
        <div class="his_r_box" v-show="enter">
          <div class="history_body_right">
            <div class="his_r_title">
              <span>收藏夹</span>
              <div class="edit_right">
                <p v-show="editShow" class="his_edit-1" @click="delfavEdit()">
                  删除选中( {{ editList.length }} )
                </p>
                <p v-show="!editShow && playList" class="his_edit" @click="favEdit(false)">
                  批量编辑
                </p>
                <p v-show="editShow" class="his_edit-2" @click="favEdit(true)">取消编辑</p>
              </div>
            </div>
            <!-- 历史播放主体 -->
            <div class="his_r_body">
              <None v-if="!playList"></None>
              <!-- 遍历循环的每一个模块 -->
              <div class="his_new" v-for="(item, index) in playList" :key="index">
                <div
                  class="his_nleft"
                  :class="{ 'his_nleft-hover': !editShow }"
                  @click="goto(item)"
                >
                  <img
                    :src="item.video_pic ? item.video_pic : ''"
                    :onerror="$store.state.defaultimg"
                    alt="收藏视频的封面图"
                  />
                  <!-- 电视剧样式 -->
                  <div class="is_series" v-show="item.multiple_fragment">
                    <img class="ab1" src="../assets/img/back_blue2.png" alt="背景样式" />
                    <img class="ab2" src="../assets/img/back_blue.png" alt="背景样式" />
                  </div>
                  <div class="his_tips" v-show="!item.multiple_fragment && item.video_duration">
                    <!-- 视频总时长 -->
                    <span>{{ YS.tomin(item.video_duration) }}</span>
                  </div>
                  <div class="his_tips" v-show="item.multiple_fragment">
                    <!-- 视频总集数 -->
                    <span v-show="item.video_state == '更新中'">
                      更新至{{ item.last_fragment_symbol }}集
                    </span>
                    <span v-show="item.video_state == '已完结'">
                      {{ item.last_fragment_symbol }}集全
                    </span>
                  </div>
                </div>
                <div class="his_nright his_nright2">
                  <div class="his_nright_top">
                    <span class="histitle" @click="goto(item)" :title="item.video_title">
                      {{ item.video_title }}
                    </span>
                    <span class="hisnodes hisn1" :title="item.video_director">
                      导演：{{ item.video_director }}
                    </span>
                    <span class="hisnodes hisn2" :title="item.video_starring">
                      主演：{{ item.video_starring }}
                    </span>
                  </div>
                  <div class="his_nright_bottom">
                    <div class="fav_label">
                      <span v-show="$store.state.menudist[item.video_category_id]">
                        {{ $store.state.menudist[item.video_category_id] }}
                      </span>
                      <span v-show="item.video_region">{{ item.video_region }}</span>
                      <span v-show="item.video_language">{{ item.video_language }}</span>
                    </div>
                    <div class="msearch_res-time">
                      <img src="../assets/img/m_his.png" alt="时间图标" />
                      <a>{{ YS.toTime2(item.create_time) }}</a>
                    </div>
                  </div>
                </div>
                <div
                  class="his_edit_box"
                  :class="{
                    edit_black: editList.filter((itemindex) => itemindex === index).length,
                  }"
                  v-show="editShow"
                  @click="checkFavEdit(item, index)"
                >
                  <div
                    class="his_edit_list"
                    :class="{
                      'his_edit_list-check': editList.filter((itemindex) => itemindex === index)
                        .length,
                      'his_edit_list-uncheck': !editList.filter((itemindex) => itemindex === index)
                        .length,
                    }"
                  ></div>
                  <!-- <div
                      class="his_edit_list his_edit_list-check"
                      @click="checkEdit(item, index)"
                    ></div> -->
                </div>
              </div>
              <!-- //挡住下划线的 -->
              <div class="block_hisbottom"></div>

              <!-- -------------------------------- -->
              <!-- <div
                class="his_r_list"
                v-for="(item, index) in playList"
                :key="index"
                @click="goto(item.id)"
              >
                <div class="his_rl_imgdiv"><img :src="item.video_pic" /></div>
                <div class="his_rl_title">
                  <a>{{ item.video_title }}</a>
                </div>
                <div class="his_rl_nodes">
                  <img src="../assets/img/play2.png" />
                  <span>{{ item.video_description }}</span>
                </div>
              </div> -->
            </div>
            <!-- 分页 -->
            <div class="pagination" v-show="fy.pageEnter && fy.pagenum > 1">
              <div class="pagebody">
                <div class="page_left" @click="YS.pagebutton('left', fy, getFavlist)">
                  <img src="../assets/img/left.png" alt="上一页" />
                </div>
                <div class="page_cent">
                  <div
                    class="page_num"
                    v-for="(item, index) in fy.pageList"
                    :key="index"
                    @click="YS.clickPage(item, fy, getFavlist)"
                    :class="{ 'page_num-act': fy.pages.page == item }"
                  >
                    <a>{{ item }}</a>
                  </div>
                </div>

                <div class="page_right" @click="YS.pagebutton('right', fy, getFavlist)">
                  <img src="../assets/img/right.png" alt="下一页" />
                </div>
                <div class="page_total">
                  <input
                    class="fenye_input"
                    v-model="fy.jump"
                    @focus="YS.getInputFocus($event)"
                    @keyup.enter="YS.clickPage(fy.jump, fy, getFavlist)"
                  />
                </div>
                <div class="page_total">
                  <span>共{{ fy.pagenum }}页</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
import None from '../components/None'; //无内容
export default {
  components: {
    Top,
    Leftside,
    None,
  },
  name: 'Favorites',
  data() {
    return {
      editList: [],
      favIDList: [],
      editShow: false,
      his_show: true,
      enter: false,
      pagetotal: null,
      playList: [],
      //分页用
      fy: {
        pageEnter: false, //第一次计算完返回true
        pagenum: null,
        pagetotal: 20,
        pages: {
          page: 1,
          pagesize: 12,
        },
        mobanpage: [1, 2, 3, 4, 5, 6, 7, 8],
        pageList: [1, 2, 3, 4, 5, '...', 11, 12],
      },
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    that = this;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (that.YS.ismob()) {
      //移动端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.MOBILE_URL_BO
          : window.g.ub_index == 2
          ? window.g.MOBILE_URL_U
          : window.g.MOBILE_URL + '/mrecord.html';
    }
  },
  mounted() {
    that.enter = true;
    that.YS.closeallWin(); //关闭所有弹窗
    that;
    that.getFavlist();
  },
  methods: {
    // 跳转指定播放页
    goto(item) {
      that.YS.routetogo(
        {
          path: '/play',
          query: { video_id: item.video_id },
        },
        true,
      );
    },
    getFavlist() {
      fetch.api_videoFavorite(that.fy.pages).then((data) => {
        that.playList = data.video_favorite_list;
        that.fy.pagetotal = data.video_favorite_total;
        that.YS.jilu(that.fy);
      });
    },
    delfavEdit() {
      if (!that.editList.length) {
        that.$refs.top.messTop = '请选择删除目标';
        that.$refs.top.mess_show = true;
        setTimeout(() => {
          that.$refs.top.mess_show = false;
        }, 3000);
        return;
      }
      that.$refs.top.smalltype = 'favEdit';
      that.YS.gotoVip({}, 'open', 'small');
    },
    //编辑选中
    checkFavEdit(item, index) {
      if (!that.editList.filter((itemindex) => itemindex === index).length) {
        that.editList.push(index);
        that.favIDList.push(item.id);
      } else {
        that.editList.splice(that.editList.indexOf(index), 1);
        that.favIDList.splice(that.editList.indexOf(item.id), 1);
      }
    },
    //点击编辑
    favEdit(type) {
      if (!type) {
        //点击编辑
        that.editShow = true;
      } else {
        //点击取消编辑
        that.editShow = false;
        that.editList = [];
        that.favIDList = [];
      }
    },
  },
};
</script>
<style lang="scss" scope>
// .top-nav {
//   background: white;
// }
// .tag-input {
//   background: #eeeeee;
//   // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
// }

// .tag-span,
// .right-content span {
//   color: rgba(51, 51, 51, 1);
// }
// .bubble {
//   background: #91d6ff;
// }
</style>
